import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actions from '../../state/actions/anchors'
import { getAnchors } from '../../state/reducers/anchors'
import AnchorLink from 'react-anchor-link-smooth-scroll'

class ListAnchors extends Component{
	constructor(props){
		super(props)

		const {anchors, anchorsInit} = props
		const anchorsObj = {}

		anchors.map((f, i) => anchorsObj[f.attrs.slug] = {...f.attrs, active: i === 0})
		anchorsInit(anchorsObj)
	}

	componentDidMount() {
		const currentUrl = document.URL, urlParts = currentUrl.split('#');
		if ( urlParts.length > 1 ) {
			const link = document.getElementById('#' + urlParts[1] + '-anchor')
			if ( link ) {
				link.click()
			}
		}
	}

	render(){
		const {anchors, anchorsState} = this.props

		return(
			<ol className="anchors-list p-0">
				{anchors.map(({attrs}, i) =>
					<li key={attrs.slug} className={`anchors-item pr-4 ${Object.keys(anchors).length !== i+1 ? "mb-4" : ""} ${anchorsState[attrs.slug] && anchorsState[attrs.slug].active ? "active" : ""}`}>
						<AnchorLink offset={() => -10} id={`#${attrs.slug}-anchor`} href={`#${attrs.slug}`} className="anchors-link">{attrs.title}</AnchorLink>
					</li>
				)}
			</ol>
		)
	}
}


const mapStateToProps = state => ({
	anchorsState: getAnchors(state)
})

export default connect(mapStateToProps, actions)(ListAnchors)
