import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  CustomInput,
  Input,
  Button,
  FormFeedback
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { Link } from "gatsby";

import { validateField } from "../../utils/functions";

import Config_local from "./../../config/Config_local";

class MemberForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
      member: this.props.member.wordpress_id,
      validCaptcha: false,
      submit: false,
      errorOnSubmit: false
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (
      this.state.validCaptcha &&
      this.state.values.email &&
      this.state.values.content &&
      this.state.values.object &&
      this.state.values.email.valid &&
      this.state.values.content.valid &&
      this.state.values.object.valid &&
      this.state.values.conditions &&
      this.state.values.conditions.valid
    ) {
      let that = this;
      axios({
        method: "post",
        url: Config_local.apiServer + "/wp-json/wp/v2/submit",
        responseType: "text",
        responseEncoding: "utf8",
        mode: "no-cors",
        data: JSON.stringify({
          member: this.props.member,
          values: this.state.values
        })
      })
        .then(function({ data }) {
          if (data.valid) {
            that.setState({ submit: true, errorOnSubmit: false });
          } else {
            that.setState({ submit: true, errorOnSubmit: true });
          }
        })
        .catch(function() {});
    } else {
      let values = { ...this.state.values };
      if (!this.state.values.email)
        values["email"] = {
          valid: false
        };

      if (!this.state.values.content)
        values["content"] = {
          valid: false
        };

      if (!this.state.values.object)
        values["object"] = {
          valid: false
        };
      if (!this.state.values.conditions)
        values["conditions"] = {
          valid: false
        };

      this.setState({ values });
    }
  }

  handleFieldChange = (event) => {
    let values = { ...this.state.values };

    if (event.target.type === "checkbox") {
      values[event.target.name] = {
        value: event.target.checked,
        valid: event.target.checked
      };
    } else {
      values[event.target.name] = {
        value: event.target.value,
        valid: validateField(
          event.target.value,
          event.target.type,
          event.target.required
        )
      };
    }

    this.setState({ values });
  }

  onCaptchaChange = (value) => {
    value !== null
      ? this.setState({ validCaptcha: true })
      : this.setState({ validCaptcha: false });
  }

  resetForm = () => {
    this.setState({ submit: false, errorOnSubmit: false });
  }

  render() {
    if (this.state.submit && this.state.errorOnSubmit) {
      return (
        <div className="member-form form-landing">
          <div className="form-landing-content">
            <p>Erreur lors de l'envoi du formulaire</p>
            <Button onClick={this.resetForm}>Retour au formulaire</Button>
          </div>
        </div>
      );
    }

    if (this.state.submit && this.state.validCaptcha) {
      return (
        <div className="member-form form-landing">
          <div className="form-landing-content">
            <p>Votre message a été envoyé</p>
            <Button onClick={this.resetForm}>Retour au formulaire</Button>
          </div>
        </div>
      );
    }

    return (
      <div className="member-form form-container">
        <Form onSubmit={this.handleSubmit} noValidate>
          <FormGroup>
            <Label for="civilite" className="mr-5">
              Civilité
            </Label>
            <div className="d-block d-sm-inline-block">
              <CustomInput
                onChange={this.handleFieldChange}
                type="radio"
                id="mme"
                name="civilite"
                value="mme"
                label="Mme"
                inline
              />
              <CustomInput
                onChange={this.handleFieldChange}
                type="radio"
                id="mr"
                name="civilite"
                value="mr"
                label="Mr"
                inline
              />
              <CustomInput
                onChange={this.handleFieldChange}
                type="radio"
                id="mlle"
                name="civilite"
                value="mlle"
                label="Mlle"
                inline
              />
            </div>
          </FormGroup>

          <Row>
            <FormGroup className="col-6">
              <Label for="lastname">Nom</Label>
              <Input
                ref="lastname"
                onChange={this.handleFieldChange}
                type="text"
                name="lastname"
                id="lastname"
              />
            </FormGroup>
            <FormGroup className="col-6">
              <Label for="firstname">Prénom</Label>
              <Input
                ref="firstname"
                onChange={this.handleFieldChange}
                type="text"
                name="firstname"
                id="firstname"
              />
            </FormGroup>
          </Row>

          <FormGroup
            className={
              this.state.values.email && !this.state.values.email.valid
                ? "invalid"
                : ""
            }
          >
            <Label for="email">Email*</Label>
            <Input
              ref="email"
              onChange={this.handleFieldChange}
              type="email"
              name="email"
              id="email"
            />
            <FormFeedback invalid="true">Champ incorrect</FormFeedback>
          </FormGroup>

          <FormGroup
            className={
              this.state.values.object && !this.state.values.object.valid
                ? "invalid"
                : ""
            }
          >
            <Label for="object">Objet du courriel*</Label>
            <Input
              ref="object"
              onChange={this.handleFieldChange}
              type="text"
              name="object"
              id="object"
              required
            />
            <FormFeedback invalid="true">Champ incorrect</FormFeedback>
          </FormGroup>

          <FormGroup
            className={
              this.state.values.content && !this.state.values.content.valid
                ? "invalid"
                : ""
            }
          >
            <Label for="content">Détail de votre demande*</Label>
            <Input
              ref="content"
              onChange={this.handleFieldChange}
              type="textarea"
              name="content"
              id="content"
              required
            />
            <FormFeedback invalid="true">Champ incorrect</FormFeedback>
          </FormGroup>

          <FormGroup
            className={`mt-5 ${
              this.state.values.conditions &&
              !this.state.values.conditions.valid
                ? "invalid"
                : ""
            }`}
          >
            <Label for="conditions">
              Utilisation des données personnelles*
            </Label>
            <div>
              <CustomInput
                onChange={this.handleFieldChange}
                required
                type="checkbox"
                id="conditions"
                name="conditions"
                label="En cochant cette case et en soumettant ce formulaire, j'accepte que mes données personnelles soient utilisées pour me recontacter dans le cadre de ma demande indiquée dans ce formulaire"
                inline
              />
            </div>
            <FormFeedback invalid="true">Ce champs est nécessaire</FormFeedback>
          </FormGroup>

          <Row>
            <FormGroup
              className={
                "captcha-container col-12 col-lg-6 " +
                (this.state.submit && !this.state.validCaptcha ? "invalid" : "")
              }
            >
              <legend>Veuillez cocher la case ci-dessous*</legend>
              <ReCAPTCHA
                sitekey={Config_local.recaptchaKey}
                onChange={this.onCaptchaChange}
                type="image"
              />
              <FormFeedback invalid="true">
                Ce champs est nécessaire
              </FormFeedback>
            </FormGroup>
            <FormGroup className="button-container col-12 col-lg-6 mt-5 mt-md-0 text-right">
              <Button className="submit-button">Envoyer</Button>
            </FormGroup>
          </Row>
        </Form>

        <Row>
          <Col>
            <p className="font-italic">
              Les informations recueillies dans le présent formulaire ont pour
              objet le traitement et le suivi de votre demande.
            </p>
            <p>
              <Link to="donnees-personnelles-formulaire-contact-elu/">
                En savoir plus sur la gestion de vos données et vos droits
              </Link>
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MemberForm;
