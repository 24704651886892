import React from "react";
import { Link } from "gatsby";
import { Container, Breadcrumb, BreadcrumbItem } from "reactstrap";
import v4 from "uuid/v4";

const BreadcrumbRbx = ({ title, id, parent, hierarchy }) => {
  function orderParents(p, ordered) {
    if (p === 0) {
      return ordered;
    } else {
      const h = hierarchy.find((e) => e.post_id === p);
      ordered.unshift({ title: h.post_title, link: h.post_link });
      return orderParents(h.post_parent, ordered);
    }
  }

  return (
    <div className="order-bottom py-2">
      <Container>
        <Breadcrumb>
          <BreadcrumbItem className="d-none d-md-block">
            <Link to="/" className="text-dark">
              Accueil
            </Link>
          </BreadcrumbItem>
          {orderParents(parent, []).map(({ link, title }, index) => (
            <BreadcrumbItem
              key={v4()}
              className={
                index < orderParents(parent, []).length - 1
                  ? "d-none d-md-block"
                  : ""
              }
            >
              <Link
                to={link}
                className="text-dark"
                dangerouslySetInnerHTML={{ __html: title }}
              ></Link>
            </BreadcrumbItem>
          ))}
          <BreadcrumbItem
            className="text-secondary d-none d-md-block"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Breadcrumb>
      </Container>
    </div>
  );
};

export default BreadcrumbRbx;
