import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'reactstrap'
import v4 from 'uuid/v4'
import { Helmet } from 'react-helmet'

import ListAnchors from '../components/ListAnchors'
import Anchor from '../components/Blocks/Cgb/Anchor'
import Breadcrumb from '../components/Breadcrumb'
import MemberForm from '../components/MemberForm'
import ScrollTop from '../components/ScrollTop'
import SocialShare from '../components/SocialShare'

import { htmlDecode } from '../utils/functions'

const MemberTemplate = ({data, pageContext, location}) =>{

	const img = data.wordpressWpTeam.featured_media && data.wordpressWpTeam.featured_media.wordpress_id && pageContext.images.find(e => e.wordpress_id === data.wordpressWpTeam.featured_media.wordpress_id)
	const featuredMedia = img && img.localFile && img.localFile.childImageSharp && img.localFile.childImageSharp.sizes

	var listAnchors = [
		{
			attrs : {
				slug: 'member-skills',
				title: 'Sphère de compétences'
			},
		},
		{
			attrs : {
				slug: 'member-contact',
				title: 'Contacter cet(te) élu(e)'
			}
		}
	]
	if ( data.wordpressWpTeam.wordpress_id === 2224 ) {
		listAnchors = [
			{
				attrs : {
					slug: 'member-contact',
					title: 'Contacter cet(te) élu(e)'
				}
			}
		]
	}

	return (
		<div className="page-template member-page-template">
			<Helmet>
				<title>{htmlDecode(data.wordpressWpTeam.title)}</title>
				{
					data.wordpressWpTeam.metadesc && data.wordpressWpTeam.metadesc !== "" &&
					<meta name="description" content={data.wordpressWpTeam.metadesc} />
				}
			</Helmet>
			<Breadcrumb title={data.wordpressWpTeam.title} id={data.wordpressWpTeam.wordpress_id} parent={data.wordpressWpTeam.wordpress_parent} hierarchy={data.wordpressWpTeam.hierarchy}/>
			<Container>
				<Row>
					<Col tag="aside" lg="4">
						<div className="member-head member-head-mobile my-5 d-flex d-lg-none">
							{featuredMedia &&
								<Img className="head-image" sizes={featuredMedia}/>
							}

							<div className="head-informations pl-5">
								{data.wordpressWpTeam.title &&
									<h1 className="title" dangerouslySetInnerHTML={{__html: data.wordpressWpTeam.title }}/>
								}
								{data.wordpressWpTeam.information.role &&
									<p className="subtitle">{data.wordpressWpTeam.information.role}</p>
								}
								<hr className="separator-grey left" />
							</div>
						</div>
						<div className="anchors-sidebar h-100 pr-5">
							<div className="bg-light h-100">
								<div className="sticky-top pl-3 pr-5 py-5">
									<ListAnchors location={location} key={v4()} anchors={listAnchors} />
									<SocialShare shareUrl={location.href} subject={data.wordpressWpTeam.title} />
								</div>
							</div>
						</div>
					</Col>
					<Col tag="article" className="py-5 position-relative page-content">

						<div className="member-head mb-5 d-none d-lg-flex">
							{featuredMedia &&
								<Img className="head-image" sizes={featuredMedia}/>
							}

							<div className="head-informations pl-5">
								{data.wordpressWpTeam.title &&
									<h1 className="title" dangerouslySetInnerHTML={{__html: data.wordpressWpTeam.title }}/>
								}
								{data.wordpressWpTeam.information.role &&
									<p className="subtitle">{data.wordpressWpTeam.information.role}</p>
								}
								<hr className="separator-grey left" />
							</div>
						</div>
						<div className="member-content mb-5">
							{ data.wordpressWpTeam.wordpress_id !== 2224 && <Anchor attrs={listAnchors[0].attrs} /> }
							{data.wordpressWpTeam.content &&
								<div dangerouslySetInnerHTML={{__html: data.wordpressWpTeam.content}}/>
							}
						</div>

						<hr />

						<div className="member-contact">
							{ data.wordpressWpTeam.wordpress_id === 2224 && <Anchor attrs={listAnchors[0].attrs} /> }
							{ data.wordpressWpTeam.wordpress_id !== 2224 && <Anchor attrs={listAnchors[1].attrs} /> }
							<Row>
								{data.wordpressWpTeam.information.address &&
									<Col md="6">
										<h3>Par courrier</h3>
										<address style={{whiteSpace : 'pre-wrap'}}>{data.wordpressWpTeam.information.address}</address>
									</Col>
								}
								{data.wordpressWpTeam.information.tel &&
									<Col md="6">
										<h3>Par téléphone</h3>
										<a className="phone" href={'tel:+' + data.wordpressWpTeam.information.tel}>{data.wordpressWpTeam.information.tel}</a>
									</Col>
								}
							</Row>
							{data.wordpressWpTeam.contact_form.show_form &&
								<Row className="my-5">
									<Col md="12">
										<h3>Par email</h3>
										<MemberForm member={data.wordpressWpTeam.wordpress_id}/>
									</Col>
								</Row>
							}
						</div>
						<ScrollTop />
					</Col>
				</Row>
			</Container>
		</div>
	)
}

MemberTemplate.propTypes = {
	data: PropTypes.object.isRequired,
	edges: PropTypes.array,
}

export default MemberTemplate

export const pageQuery = graphql`
	query($id: String!) {
		wordpressWpTeam(id: { eq: $id }) {
			id
			wordpress_id
			metadesc
			slug
			full_slug
			title
			content
			information{
				role
				address
				tel
			}
			contact_form{
				show_form
				main_mail
				cc_mail{
					email
				}
			}
			featured_media{
				wordpress_id
			}
			hierarchy {
				post_id
				post_name
				post_title
				post_parent
				post_link
				menu_order
			}
			wordpress_parent
			categories{
				team
				tags
			}
		}
	}
`
